import React, { Component } from "react";
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  withStyles,
  MenuItem,
} from "@material-ui/core";
import PlanificationCss from "./css/PlanificationCss";
import { connect } from "react-redux";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import LocalShipping from "@material-ui/icons/LocalShipping";
import PermIdentity from "@material-ui/icons/PermIdentity";
import {
  Add,
  AddCircle,
  Archive,
  Business,
  BusinessCenter,
  CheckBox,
  CropFree,
  History,
  Info,
  Keyboard,
  List as ListIcon,
} from "@material-ui/icons";
import SpeakerNotes from "@material-ui/icons/SpeakerNotes";
import {
  collectionActions,
  updateAffairePlanning,
  updateAffairePesee,
} from "../../../reducers/collectionsReducer";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import format from "date-fns/format";
import { MuiPickersUtilsProvider, TimePicker } from "material-ui-pickers";
import Modal from "../../common/Components/Modal";
import PropTypes from "prop-types";
import { buildRoute } from "../../../router/Tools";
import CheckboxInput from "../../common/Components/CheckboxInput";
import { hasRights } from "../../common/Tools/Tools";
import { axiosClient } from "../../../axios";
import {
  addNotification,
  axiosErrorHandler,
} from "../../../reducers/notificationReducer";
import { themeComplement } from "../AppAdmin/css/theme";
import TextInput from "../../common/Components/TextInput";
import DocumentPicker from "../../common/Components/DocumentPicker";

class FrDateFnsUtils extends DateFnsUtils {
  startOfMonth(date) {
    return moment(date).startOf("month").toDate();
  }

  getDatePickerHeaderText(date) {
    return format(date, "d MMMM", { locale: this.locale });
  }
}

class Planning extends Component {
  state = {
    visible: false,
    openCommentModal: false,
    openProductionCommentModal: false,
    commentaireContent: "",
    commentaireTime: null,
    commentaireDate: null,
    errorselectedDate: false,
    errorCommentaire: false,
    currentListItem: null,
    commentaire: null,
    commentUuid: null,
    openAdditionnalAffaireInformationModal: false,
    localAffaire: null,
    error: {},
    openConfirmationCamionPasseModal: false,
  };

  componentDidMount() {
    if (!this.props.flux) {
      this.loadPlanning();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.site !== this.props.site ||
      prevProps.selectedDate !== this.props.selectedDate
    ) {
      this.loadPlanning();
    }
  }

  loadPlanning() {
    const { selectedDate, site, forModulePhoto } = this.props;
    const date =
      selectedDate.getFullYear() +
      "-" +
      (selectedDate.getMonth() + 1) +
      "-" +
      selectedDate.getDate() +
      " " +
      selectedDate.getHours() +
      ":" +
      selectedDate.getMinutes() +
      ":" +
      selectedDate.getSeconds();

    const etablissement_uuid = site === "externe" ? null : site;
    const externe = site === "externe";

    collectionActions(this.props.dispatch, "flux", "INDEX", {
      params: { date, site, forModulePhoto },
    });
    collectionActions(this.props.dispatch, "commentaires", "INDEX", {
      params: { date: date, etablissement_uuid, externe },
    });
  }

  handleAdditionnalAffaireInformationModal(flux) {
    this.setState(
      {
        localAffaire: flux,
      },
      () =>
        this.setState({
          openAdditionnalAffaireInformationModal:
            !this.state.openAdditionnalAffaireInformationModal,
        })
    );
  }

  displayAddButton(flux) {
    const { classes } = this.props;
    const { user } = this.props;
    const hasEditRight = hasRights("affaire-saisie-complementaire", user);
    if (hasEditRight) {
      return (
        <IconButton
          className={classes.additionnalAddBtn}
          onClick={this.handleAdditionnalAffaireInformationModal.bind(
            this,
            flux
          )}
        >
          <AddCircle />
        </IconButton>
      );
    }
  }

  callBackToOpenPhotoModal(flux) {
    this.props.callPhotoDialog(flux);
  }

  getDaysContent(flux) {
    const { classes, user } = this.props;
    const hasEditRight = hasRights("admin-cud-affaires", user);
    let content = [];
    content.push(
      <>
        <span className={classes.reference} key={flux.reference_interne}>
          Réf :{" "}
          <a
            href={buildRoute("/affaires/:uuid", {
              uuid: flux.uuid,
            })}
          >
            {flux.reference_interne}
          </a>
        </span>
        <CheckboxInput
          className={classes.checkboxPasse}
          id="camion_passe"
          label="Passé"
          value={flux.camion_passe}
          disabled={!hasEditRight}
          margin="normal"
          name="camion_passe"
          onChangeHandler={(name, e) => {
            this.handleCamionPasse(flux);
          }}
        />
        {this.displayAddButton(flux)}
      </>
    );

    content.push(
      <ul className={classes.liste} key={"ul-" + flux.reference_interne}>
        {Object.keys(flux.lots).map((index, key) => {
          let lot = flux.lots[key];

          return (
            <li key={lot.uuid}>
              {lot.conditionnement_nom} / {lot.article_famille_nom} /{" "}
              {lot.article_nom}
            </li>
          );
        })}
      </ul>
    );

    content.push(
      <span
        key={new Date().getTime()}
        className={classes.transportNom}
        style={{ backgroundColor: flux.transport_planning_color }}
      >
        <LocalShipping className={classes.transportIcon} fontSize={"small"} />
        {flux.transporteur_nom} ({flux.transport_nom}){" "}
        {flux.stock_in_case_references}
      </span>
    );

    content.push(
      <div className={classes.poidsAndCommercialContainer}>
        <span key={flux.poids_estime} className={classes.transportNom}>
          <Archive className={classes.transportIcon} fontSize={"small"} />
          {flux.poids_estime} t
        </span>
        <span
          key={flux.planning_commercial_initiales}
          className={classes.transportNom}
        >
          <BusinessCenter
            className={classes.transportIcon}
            fontSize={"small"}
          />
          {flux.planning_commercial_initiales}
        </span>
        <span key={flux.logisticien_initiales} className={classes.transportNom}>
          <Keyboard className={classes.transportIcon} fontSize={"small"} />
          {flux.logisticien_initiales}
        </span>
      </div>
    );

    content.push(
      <span key={flux.lieu_enlevement} className={classes.transportNom}>
        <Business className={classes.transportIcon} fontSize={"small"} />
        {flux.lieu_enlevement.nom} ({flux.lieu_enlevement.ville})
      </span>
    );

    if (this.props.exterior) {
      content.push(
        <span key={flux.lieu_livraison.nom} className={classes.transportNom}>
          <PermIdentity className={classes.transportIcon} fontSize={"small"} />
          {flux.lieu_livraison.nom}
        </span>
      );
    }

    flux.packing_list_flux.forEach((packingListFlux) => {
      content.push(
        <a
          key={packingListFlux.reference}
          className={classes.ListItemPL}
          style={{ backgroundColor: packingListFlux.color }}
          title={packingListFlux.reference}
          href={
            packingListFlux.uuid
              ? buildRoute("/pslists/packinglists/:uuid", {
                  uuid: packingListFlux.uuid,
                })
              : "#"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <ListIcon />
        </a>
      );
    });

    let displayPhotoBtn = null;

    if (this.props.forModulePhoto) {
      displayPhotoBtn = (
        <div>
          <DocumentPicker
            documentable_type="App\Models\Affaire"
            documentable_uuid={flux.uuid}
            hasDocument={flux.has_document}
          />
        </div>
      );
    }

    return (
      <>
        <span>{content}</span>
        {displayPhotoBtn}
      </>
    );
  }

  // Retourne le bouton d'ajout de commentaires
  getAddCommentButton(key) {
    const { classes, selectedDate } = this.props;

    return (
      <IconButton
        color="primary"
        className={classes.addCommentButton}
        size="small"
        onClick={() => {
          let date = selectedDate;

          let dateValue = key.split("/");
          date.setDate(dateValue[0]);
          date.setMonth(dateValue[1] - 1);
          date.setFullYear(dateValue[2]);

          this.setState({
            openCommentModal: true,
            selectedDate: date,
          });
        }}
        title="Ajouter un commentaire"
      >
        <Add />
        <SpeakerNotes />
      </IconButton>
    );
  }

  // Retourne la liste des commentaires
  getComments(selectedCommentaires) {
    const { classes } = this.props;
    let comments_tab = [];
    let commentTime;

    selectedCommentaires.map((commentaire) => {
      if (commentaire.time) {
        commentTime = commentaire.time.split(":");
      }
      comments_tab.push(
        <div
          key={commentaire.uuid}
          className={classes.commentaire}
          // Handler en entrée du survol de la souris
          onMouseEnter={() => {
            this.onMouseEnterCommentaireHandler(commentaire);
          }}
          // Handler en sortie du survol de la souris
          onMouseLeave={() => {
            this.onMouseLeaveCommentaireHandler(commentaire);
          }}
        >
          <Typography color={"primary"} className={classes.commentHour}>
            {commentTime ? commentTime[0] + ":" + commentTime[1] : ""}
          </Typography>
          <Typography color={"primary"}>{commentaire.content}</Typography>
          {this.getEditDeleteCommentButton(commentaire)}
        </div>
      );
      commentTime = null;
      return comments_tab;
    });
    return (
      <Paper className={classes.comment} elevation={1}>
        <SpeakerNotes className={classes.backIcon} />
        {comments_tab}
      </Paper>
    );
  }

  // Retourne les boutons d'édition et de suppression des commentaires
  getEditDeleteCommentButton(commentaire) {
    if (!commentaire) return;

    const { classes } = this.props;
    let commDate = commentaire.date.split(" ");
    let datestr = commDate[0].split("/");
    let date = new Date();
    if (commentaire.time !== null && commentaire.time !== undefined) {
      let timestr = commentaire.time.split(":");
      date.setHours(timestr[0], timestr[1], timestr[2]);
    } else {
      date = null;
    }
    if (date) {
      date.setFullYear(datestr[0], datestr[1] - 1, datestr[2]);
    }

    // Test le commentaire survolé pour n'afficher les boutons que sur celui ci
    if (this.state.commentUuid === commentaire.uuid) {
      return (
        <div className={classes.editDeleteComments}>
          <IconButton
            className={classes.editCommentButton}
            size="small"
            onClick={() => {
              this.setState({
                openCommentModal: true,
                commentaireContent: commentaire.content,
                commentaireTime: date,
                commentaireDate: date,
                commentaire: commentaire,
              });
            }}
            title="Modifier"
          >
            <Edit />
          </IconButton>
          <IconButton
            className={classes.deleteCommentButton}
            size="small"
            onClick={() => {
              collectionActions(
                this.props.dispatch,
                "commentaires",
                "DELETE",
                commentaire
              );
            }}
            title="Supprimer"
          >
            <Delete />
          </IconButton>
        </div>
      );
    }
  }

  // Handler sur le changement du contenu du commentaire
  changeCommentHandler(event) {
    this.setState({
      commentaireContent: event.target.value,
    });
  }

  // Handler sur le changement de l'heure du commentaire
  changeHourHandler(time) {
    this.setState({
      commentaireTime: time,
    });
  }

  // Handler sur le changement lors du survol du commentaire
  onMouseEnterCommentaireHandler(commentaire) {
    this.setState({
      commentUuid: commentaire.uuid,
    });
  }

  // Handler sur le changement lors du survol du commentaire
  onMouseLeaveCommentaireHandler() {
    this.setState({
      commentUuid: null,
    });
  }

  // Retourne le contenu de la modal de commentaire
  getContentCommentModal() {
    const { classes } = this.props;
    return (
      <>
        <DialogTitle className={classes.modalTitle}>
          <SpeakerNotes className={classes.ico} /> Ajout d'un commentaire
        </DialogTitle>
        <DialogContent>
          <TextField
            id="comment"
            label="Commentaire"
            value={this.state.commentaireContent}
            onChange={this.changeCommentHandler.bind(this)}
            fullWidth
            multiline
            minRows={4}
            required
            error={this.state.errorCommentaire}
            helperText={this.state.errorCommentaire ? "Champ obligatoire" : ""}
          ></TextField>
          <MuiPickersUtilsProvider utils={FrDateFnsUtils}>
            <Grid container>
              <TimePicker
                margin="normal"
                id="hour"
                label="Heure (facultatif)"
                value={this.state.commentaireTime}
                onChange={this.changeHourHandler.bind(this)}
                KeyboardButtonProps={{
                  "aria-label": "change time",
                }}
                clearable={false}
                cancelLabel="Annuler"
                ampm={false}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </DialogContent>
      </>
    );
  }

  // Handler sur la validation de création de commentaire
  onSubmitHandlerCommentModal(commentaire, name) {
    const { selectedDate } = this.state;
    const { site } = this.props;

    // Test si le commentaire existe
    if (commentaire === null) {
      // Test si les entrées obligatoires sont remplies
      if (
        this.state.selectedDate === "" ||
        this.state.commentaireContent === ""
      ) {
        if (this.state.selectedDate === "") {
          this.setState({ errorselectedDate: true });
        }

        if (this.state.commentaireContent === "") {
          this.setState({ errorCommentaire: true });
        }
      } else {
        let newCommentaire = {};

        if (this.state.commentaireDate !== null) {
          newCommentaire.date = this.state.commentaireDate;
        } else {
          newCommentaire.date =
            selectedDate.getFullYear() +
            "-" +
            (selectedDate.getMonth() + 1) +
            "-" +
            selectedDate.getDate();
        }

        if (this.state.commentaireTime !== null) {
          newCommentaire.time =
            this.state.commentaireTime.getHours() +
            ":" +
            this.state.commentaireTime.getMinutes() +
            ":" +
            this.state.commentaireTime.getSeconds();
        }
        newCommentaire.content = this.state.commentaireContent;
        newCommentaire.etablissement_uuid = site === "externe" ? null : site;
        newCommentaire.externe = site === "externe";

        collectionActions(
          this.props.dispatch,
          "commentaires",
          "CREATE",
          newCommentaire,
          () => {
            collectionActions(this.props.dispatch, "commentaires", "INDEX", {
              params: {
                // Attribut la date du jour correspondant au commentaire
                date:
                  selectedDate.getFullYear() +
                  "-" +
                  (selectedDate.getMonth() + 1) +
                  "-" +
                  selectedDate.getDate(),
                etablissement_uuid: newCommentaire.etablissement_uuid,
                externe: newCommentaire.externe,
              },
            });
          }
        );

        this.onCloseCommentModal();
      }
    } else {
      commentaire.content = this.state.commentaireContent;
      if (this.state.commentaireTime) {
        commentaire.time =
          this.state.commentaireTime.getHours() +
          ":" +
          (this.state.commentaireTime.getMinutes() < 10 ? "0" : "") +
          this.state.commentaireTime.getMinutes() +
          ":" +
          this.state.commentaireTime.getSeconds();
      }
      collectionActions(
        this.props.dispatch,
        "commentaires",
        "UPDATE",
        commentaire,
        () => {
          this.setState({
            openCommentModal: false,
            commentaireContent: "",
            commentaireTime: null,
            commentaire: null,
          });
        }
      );
    }
  }

  getListItemCommentIcon(listItem) {
    const { classes } = this.props;
    if (listItem.commentaire_production) {
      return (
        <IconButton
          className={classes.listItemCommentIcon}
          size="small"
          onClick={() => {
            this.setState({
              openProductionCommentModal: true,
              currentListItem: listItem,
            });
          }}
        >
          <Info />
        </IconButton>
      );
    }
  }

  getContentProductionCommentModal() {
    const { classes } = this.props;
    return (
      <>
        <DialogTitle className={classes.modalTitle}>
          <SpeakerNotes className={classes.ico} />{" "}
          {this.state.currentListItem
            ? this.state.currentListItem.entreprise_flux
            : null}{" "}
          Réf:{" "}
          {this.state.currentListItem
            ? this.state.currentListItem.reference_interne
            : null}
        </DialogTitle>
        <DialogContent>
          <Typography>
            {this.state.currentListItem
              ? this.state.currentListItem.commentaire_production
              : null}
          </Typography>
        </DialogContent>
      </>
    );
  }

  getContentConfirmationCamionPasseModal() {
    const { classes } = this.props;
    return (
      <>
        <DialogTitle className={classes.modalTitle}>
          <SpeakerNotes className={classes.ico} /> Confirmation
        </DialogTitle>
        <DialogContent>
          <Typography>
            "Cocher la case "camion passé" sort les PL du stock. Êtes-vous sûr
            de vouloir confirmer le passage du camion ?
          </Typography>
        </DialogContent>
      </>
    );
  }

  handleCamionPasse(flux) {
    if (flux.packing_list_flux.length > 0) {
      this.setState({
        openConfirmationCamionPasseModal: true,
        flux: flux,
      });
    } else {
      updateAffairePlanning(this.props.dispatch, flux.uuid, {
        camion_passe: !flux.camion_passe,
      });
    }
  }

  onSubmitConfirmationCamionPasseModal() {
    const { flux } = this.state;
    updateAffairePlanning(this.props.dispatch, flux.uuid, {
      camion_passe: !flux.camion_passe,
    });
    this.onCloseConfirmationCamionPasseModal();
  }

  // MODALE DE PESEE
  onSubmitAdditionnalAffaireInformationModal() {
    const { localAffaire, error } = this.state;
    if (Object.values(error).includes(true)) {
      return;
    }

    updateAffairePesee(
      this.props.dispatch,
      localAffaire.uuid,
      localAffaire,
      this.updateDone.bind(this)
    );
  }

  updateDone() {
    this.setState({ localAffaire: null }, () => this.loadPlanning());
    this.onCloseAdditionnalAffaireInformationModal();
  }

  onChangeAffairePeseHandler(name, event) {
    this.setState({
      localAffaire: {
        ...this.state.localAffaire,
        [name]: event.target.value,
      },
    });
  }

  onChangePeseHandler(name, event) {
    const { value } = event.target;

    var updated = false;

    const pesee_complementaire =
      this.state.localAffaire.pesee_complementaire.map((pese) => {
        if (pese.ordre === name) {
          updated = true;
          return {
            ...pese,
            poid: value,
          };
        }
        return pese;
      });

    if (!updated) {
      pesee_complementaire.push({
        affaire_uuid: this.state.localAffaire.uuid,
        ordre: name,
        poid: value,
      });
    }
    this.setState(
      {
        localAffaire: {
          ...this.state.localAffaire,
          pesee_complementaire: pesee_complementaire,
        },
      },
      () => {
        document.getElementById("pesecomplementaire_" + name).focus();
      }
    );
  }

  validationFieldPese(name, event) {
    const { value } = event.target;
    if (value > 99999999) {
      this.setState({
        error: {
          ...this.state.error,
          [name]: true,
        },
      });
    } else {
      this.setState({
        error: {
          ...this.state.error,
          [name]: false,
        },
      });
    }
  }

  displayChampDePesse() {
    const { localAffaire, error } = this.state;
    const { affairesStore } = this.props;
    if (localAffaire) {
      let maxOrder = 0;

      let peseesExistante = localAffaire.pesee_complementaire.map((pese) => {
        if (pese.ordre > maxOrder) {
          maxOrder = pese.ordre;
        }
        return (
          <Grid item xs={6}>
            <TextInput
              id={"pesecomplementaire_" + pese.ordre}
              label={"Réf pesée n°" + pese.ordre}
              value={pese.poid}
              margin="normal"
              collectionStore={affairesStore}
              name={pese.ordre}
              onChangeHandler={this.onChangePeseHandler.bind(this)}
              fullWidth
              type="number"
              inputProps={{ min: 0, max: 99999999 }}
              helperText={
                error.premiere_pesee ? "8 caractères numérique maximum" : null
              }
              error={error.pesee_complementaire}
            ></TextInput>
          </Grid>
        );
      });

      let nouvellePese =
        localAffaire.pesee_complementaire.length < 8 ? (
          <Grid item xs={6}>
            <TextInput
              id="nouvelle_pese"
              label="nouvelle pese"
              value={localAffaire.nouvelle_pese}
              margin="normal"
              collectionStore={affairesStore}
              name={maxOrder + 1}
              onChangeHandler={this.onChangePeseHandler.bind(this)}
              fullWidth
              type="number"
              inputProps={{ min: 0, max: 99999999 }}
              helperText={
                error.premiere_pesee ? "8 caractères numérique maximum" : null
              }
              error={error.pesee_complementaire}
            ></TextInput>
          </Grid>
        ) : null;

      return (
        <Grid container spacing={2}>
          {peseesExistante}
          {nouvellePese}
        </Grid>
      );
    }
  }

  AdditionnalAffaireInformation() {
    const { localAffaire } = this.state;

    const listOptionsNumCarte = [];
    for (let i = 1; i < 21; i++) {
      listOptionsNumCarte.push({ value: i, label: i });
    }
    const { classes, affairesStore } = this.props;
    return (
      <>
        <DialogTitle className={classes.modalTitle}>
          Info complémentaire :
          {this.state.localAffaire
            ? this.state.localAffaire.entreprise_flux
            : null}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput
                id="plaque_remorque"
                label="Plaque remorque"
                value={localAffaire?.plaque_remorque}
                margin="normal"
                collectionStore={affairesStore}
                name="plaque_remorque"
                onChangeHandler={this.onChangeAffairePeseHandler.bind(this)}
                fullWidth
                inputProps={{ maxLength: 30 }}
              ></TextInput>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                id="num_carte"
                label="N° carte"
                value={localAffaire?.num_carte}
                margin="normal"
                collectionStore={affairesStore}
                name="num_carte"
                onChangeHandler={this.onChangeAffairePeseHandler.bind(this)}
                fullWidth
                select
              >
                {listOptionsNumCarte.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextInput>
            </Grid>
            {this.displayChampDePesse()}
          </Grid>
        </DialogContent>
      </>
    );
  }

  onCloseProductionCommentModal() {
    this.setState({
      openProductionCommentModal: false,
    });
  }

  onCloseAdditionnalAffaireInformationModal() {
    this.setState({
      openAdditionnalAffaireInformationModal: false,
      error: {},
    });
  }

  onCloseConfirmationCamionPasseModal() {
    this.setState({
      openConfirmationCamionPasseModal: false,
      flux: null,
    });
  }

  // Handler sur la fermeture de la modal de commentaire
  onCloseCommentModal() {
    this.setState({
      openCommentModal: false,
      commentaireContent: "",
      commentaireTime: null,
      commentaire: null,
    });
  }

  getStatutMarqueur(etat) {
    const { classes } = this.props;

    if (etat.secure_transport) {
      return (
        <CheckBox title="Confirmé" id="statut" className={classes.statutIcon} />
      );
    } else if (etat.is_previsionnel) {
      return (
        <History title="Prev" id="statut" className={classes.statutIcon} />
      );
    } else {
      return (
        <CropFree
          title="À Confirmer"
          id="statut"
          className={classes.statutIcon}
        />
      );
    }
  }

  getArrow(flux) {
    const { classes } = this.props;
    let livraison = flux.type_flux === "livraison",
      chargement = flux.type_flux === "chargement";
    var className = this.getClassTaff(flux);

    if (!className) {
      if (livraison) {
        if (flux.lieu_livraison.display_into_planning) {
          className = classes.arrivee;
        } else {
          className = classes.arriveeCapEco;
        }
      } else {
        if (flux.lieu_enlevement.display_into_planning) {
          className = classes.depart;
        } else {
          className = classes.departCapEco;
        }
      }
    }
    return (
      <Avatar
        title={
          livraison
            ? "Livraison"
            : chargement
            ? "Chargement"
            : "Chargement extérieur"
        }
        className={className}
      >
        {livraison || flux.type_slug === "taf-entrant" ? (
          <ArrowDownwardIcon />
        ) : (
          <ArrowUpwardIcon />
        )}
      </Avatar>
    );
  }

  getDaySynthese(jour) {
    const content = [];
    if (!this.props.exterior) {
      content.push(
        <div key={"L"}>
          R: {jour.livraison.nb} /{" "}
          {Math.round(jour.livraison.poids * 100) / 100} T
        </div>
      );
      content.push(
        <div key={"E"}>
          E: {jour.enlevement.nb} /{" "}
          {Math.round(jour.enlevement.poids * 100) / 100} T
        </div>
      );
    }

    content.push(
      <div key={"T"}>
        T: {jour.total.nb} / {Math.round(jour.total.poids * 100) / 100} T
      </div>
    );

    return content;
  }

  onChangeBlocage(jour) {
    axiosClient
      .post("/blocage-planning", {
        date: jour.blocage.date,
        etablissement_uuid:
          jour.blocage.etablissement_uuid === "externe"
            ? null
            : jour.blocage.etablissement_uuid,
        locked: !jour.blocage.locked,
      })
      .then((response) => {
        collectionActions(
          this.props.dispatch,
          "flux",
          "INDEX_WITHOUT_DISPATCH",
          {
            params: { date: jour.blocage.date, site: this.props.site },
          }
        ).then((response) => {
          this.props.dispatch({
            type: "LIST_FULFILLED",
            collection: "flux",
            payload: response.data,
          });
        });
        addNotification(this.props.dispatch, {
          message: "Blocage mis à jour",
          bgColor: themeComplement.palette.notifications.success.color,
        });
      })
      .catch((error) => {
        axiosErrorHandler(this.props.dispatch, error);
      });
  }

  getBloquage(jour) {
    const { classes } = this.props;
    if (!jour.blocage.locked) return null;

    return <h5 className={classes.bloqued}>BLOQUÉE</h5>;
  }

  getClassTaff(flux) {
    const { classes } = this.props;
    if (
      ["taf-sortant", "taf-entrant"].includes(flux.type_slug) &&
      flux.type_flux === "exterieur"
    ) {
      return classes.taf;
    } else {
      return null;
    }
  }

  getDays() {
    const { classes, forModulePhoto } = this.props;
    let days = [],
      nbrResultats = Object.keys(this.props.fluxStore.list).length,
      columnWidth = nbrResultats > 5 ? 100 / nbrResultats + "%" : "20%";

    if (forModulePhoto) {
      columnWidth = "100%";
    }

    days.push(
      Object.keys(this.props.fluxStore.list).map((key, index) => {
        let jour = this.props.fluxStore.list[key],
          flux = [];

        // Flux livraison/chargement
        flux.push(
          Object.keys(jour.flux).map((key, index) => {
            let date = new Date(jour.flux[key].date_flux),
              heure =
                (date.getHours() < 10
                  ? "0" + date.getHours()
                  : date.getHours()) +
                ":" +
                (date.getMinutes() < 10
                  ? "0" + date.getMinutes()
                  : date.getMinutes()),
              result = null;

            result = (
              <div key={key}>
                <ListItem
                  dense
                  disableGutters
                  divider
                  alignItems={"flex-start"}
                  className={classes.listItem}
                >
                  <div className={classes.statutAndTypeContainer}>
                    {this.getArrow(jour.flux[key])}
                    {this.getStatutMarqueur(jour.flux[key].etat)}
                  </div>
                  <ListItemText
                    className={classes.listItemText}
                    primaryTypographyProps={{
                      className: classes.primaryTypography,
                    }}
                    secondaryTypographyProps={{
                      className: classes.secondaryTypography,
                    }}
                    primary={
                      <>
                        <span className={classes.heures}>{heure}</span>
                        <span className={classes.entreprise}>
                          {jour.flux[key].entreprise_flux}
                        </span>
                      </>
                    }
                    secondary={this.getDaysContent(jour.flux[key])}
                  />
                  {this.getListItemCommentIcon(jour.flux[key])}
                </ListItem>
              </div>
            );

            return result;
          })
        );

        let commentVisible = false;
        let selectedCommentaires = [];
        if (this.props.commentairesStore.list) {
          this.props.commentairesStore.list.map((commentaire) => {
            let newDateAndHour = commentaire.date.split(" ");
            let newdateYmd = newDateAndHour[0].split("/");

            if (
              newdateYmd[2] + "/" + newdateYmd[1] + "/" + newdateYmd[0] ===
              key
            ) {
              commentVisible = true;
              selectedCommentaires.push(commentaire);
            }
            return commentVisible;
          });
        }

        let dateComplete = key.split("/");
        let displayDatekey = dateComplete[0] + "/" + dateComplete[1];
        // Colonne
        return (
          <Paper
            key={jour.label}
            className={classes.column}
            elevation={1}
            style={{ width: columnWidth }}
          >
            <Typography
              variant="h5"
              component="h3"
              color="primary"
              className={classes.date}
            >
              {displayDatekey}
              {this.getBloquage(jour)}
            </Typography>
            <Typography
              variant="h5"
              component="h3"
              color="primary"
              className={classes.jour}
            >
              <div className={classes.syntheseDay}>
                {this.getDaySynthese(jour)}
              </div>
              <div className={classes.blocageDay}>
                <CheckboxInput
                  value={jour.blocage.locked}
                  disabled={
                    !hasRights(["logistique-blocage-planning"], this.props.user)
                  }
                  margin="normal"
                  title="Blocage de la journée"
                  onChangeHandler={this.onChangeBlocage.bind(this, jour)}
                />
              </div>
              <div className={classes.libelleDay}>{jour.label}</div>
            </Typography>
            <div className={classes.mainContainer}>
              {this.props.admin ? (
                <div style={{ textAlign: "center" }}>
                  {commentVisible ? this.getComments(selectedCommentaires) : ""}
                </div>
              ) : (
                ""
              )}
              <List className={classes.root}>{flux}</List>
              {this.getAddCommentButton(key)}
            </div>
          </Paper>
        );
      })
    );

    // Conteneur
    return <div className={classes.container}>{days}</div>;
  }

  render() {
    const { classes } = this.props;

    if (this.props.fluxStore.fetching) return null;

    return (
      <>
        <Grid container item className={classes.container}>
          {this.props.fluxStore.list ? this.getDays() : null}
        </Grid>
        <Modal
          openModal={this.state.openCommentModal}
          onCloseHandler={this.onCloseCommentModal.bind(this)}
          onSubmitHandler={this.onSubmitHandlerCommentModal.bind(
            this,
            this.state.commentaire
          )}
          fullWidth={true}
          maxWidth={"sm"}
          children={this.getContentCommentModal()}
          disabledEnter={true}
        />
        <Modal
          openModal={this.state.openProductionCommentModal}
          onCloseHandler={this.onCloseProductionCommentModal.bind(this)}
          fullWidth={true}
          maxWidth={"sm"}
          children={this.getContentProductionCommentModal()}
          actionSubmit="NONE"
          disabledEnter={true}
          onSubmitHandler={() => {}}
        />
        <Modal
          openModal={this.state.openAdditionnalAffaireInformationModal}
          onCloseHandler={this.onCloseAdditionnalAffaireInformationModal.bind(
            this
          )}
          fullWidth={true}
          maxWidth={"sm"}
          children={this.AdditionnalAffaireInformation()}
          disabledEnter={true}
          onSubmitHandler={this.onSubmitAdditionnalAffaireInformationModal.bind(
            this
          )}
        />
        <Modal
          openModal={this.state.openConfirmationCamionPasseModal}
          onCloseHandler={this.onCloseConfirmationCamionPasseModal.bind(this)}
          fullWidth={true}
          maxWidth={"sm"}
          children={this.getContentConfirmationCamionPasseModal()}
          disabledEnter={true}
          onSubmitHandler={this.onSubmitConfirmationCamionPasseModal.bind(this)}
        />
      </>
    );
  }
}

Planning = connect((store) => {
  return {
    fluxStore: store.collections.flux,
    commentairesStore: store.collections.commentaires,
    user: store.auth.user,
    affairesStore: store.collections.affaires,
    caseStockageStore: store.collections.caseStockage,
  };
})(Planning);

Planning.propTypes = {
  exterior: PropTypes.bool, // Détermine si on doit demander les flux extérieurs a CapEco auprès de l'API
  admin: PropTypes.bool, // Détermine si on doit afficher les commentaires
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

Planning = withStyles(PlanificationCss)(Planning);

export default Planning;
