export default theme => ({
  root: {
    flexGrow: 1
  },
  crudTitle: {
    color: theme.palette.primary.main,
    marginBottom: '1em'
  },
  button: {
    float: 'right'
  },
  addButton: {
    marginLeft: '2em',
    marginTop: '0.5em',
    marginRight: '0.5em'
  },
  addButtonIcon: {
    fontSize: '32px'
  },
  stockBtn: {
    float: 'right'
  },
  error: {
    color: theme.palette.error.main
  },
  selectContainer: {
    width: 'calc(100% - 1em)'
  },
  backgroundGrey: {
    backgroundColor: '#D3D3D3'
  },
  noBackgroundColor: {
    backgroundColor: 'transparent!important'
  },
  conditionnement: {
    backgroundColor: 'transparent!important',
    maxWidth: '10vw',
  },
});
